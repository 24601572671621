
import React from "react"
import Layout from "../../components/layout"
import { Container, Row, Col, Alert } from "reactstrap"
import { StaticImage } from 'gatsby-plugin-image'
import 'bootstrap/dist/css/bootstrap.css'


let P11 = () => (

    <Layout>
        <Container className="py-5">
            <Row>
                <Col xs={12} md={3} className="mb-3">
                    <StaticImage src="../../data/product/p11.jpg" />
                    <div style="line-height: 30px;"><span style="font-size: 16px;">铁路道口栏杆</span><br style="" /></div>
                </Col>
                <Col xs={12} md={9} className="mb-9">
                    <Alert color="primary">
                        产品详情
                    </Alert>
                    <div style="line-height: 30px;"><span style="font-size: 16px;">主要支持对道口的网络管理。实现信息远程交互、统计分析等。使各级管理部门能够实时掌握道口现状，排查隐患并及时纠正。减少设备的日常养护成本，减低运行费用。据此实现道口管理的网络化、数字化。</span><br style="" /></div>

                </Col>
            </Row>
        </Container>
    </Layout>
)

export default P11



